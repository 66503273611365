import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"

const Header = ({ siteTitle }) => {

    const triggerGlobalMenu = () => {
        var element = document.getElementById("ubc7-global-menu");
        element.classList.toggle("in");
    }
    return (
        <React.Fragment>
            <Helmet>
            <link type="text/css" href="https://cdn.ubc.ca/clf/7.0.5/css/ubc-clf-full.min.css" rel="stylesheet"></link>
            <body className="full-width app" />
            </Helmet>
            <a className="skip-link" href="#main">Skip to main content</a>
            <div className="collapse expand" id="ubc7-global-menu">
                <div id="ubc7-search" className="expand">
                    <div id="ubc7-search-box">
                        <form className="form-search" method="get" action="https://www.ubc.ca/search/refine/" role="search">
                            <input aria-label="Search this website" type="text" name="q" placeholder="Search this website" className="input-xlarge search-query" />
                            <input aria-hidden="true" type="hidden" name="label" value="Search UBC" />
                            <input aria-hidden="true" type="hidden" name="site" value="*.ubc.ca" />
                            <button type="submit" className="btn">Search</button>
                        </form>
                    </div>
                </div>
                <div className="container">
                    <div id="ubc7-global-header" className="expand">
                        <div className="row-fluid"><div className="span8 offset2"><ul className="reverse"><li><a href="https://cdn.ubc.ca/clf/ref/calendar">Academic Calendar</a></li><li><a href="https://cdn.ubc.ca/clf/ref/campus-services">Campus Services</a></li><li><a href="https://cdn.ubc.ca/clf/ref/faculties">Faculties &amp; Schools</a></li><li><a href="https://cdn.ubc.ca/clf/ref/library">Library</a></li><li><a href="https://cdn.ubc.ca/clf/ref/maps">Maps</a></li><li><a href="https://cdn.ubc.ca/clf/ref/quicklinks">Quicklinks</a></li><li><a href="https://cdn.ubc.ca/clf/ref/ssc">Student Service Centre</a></li><li><a href="https://cdn.ubc.ca/clf/ref/directories">UBC Directory</a></li></ul></div></div>
                    </div>
                </div>
            </div>
            <header id="ubc7-header" className="row-fluid expand" role="banner">
                <div className="container">
                    <div className="span1">
                        <div id="ubc7-logo">
                            <a href="https://www.ubc.ca" title="The University of British Columbia (UBC)">The University of British Columbia</a>
                        </div>
                    </div>
                    <div className="span11" id="ubc7-wordmark-block">
                        <div id="ubc7-wordmark">
                            <a href="https://www.ubc.ca" title="The University of British Columbia (UBC)">The University of British Columbia</a>
                        </div>
                        <div id="ubc7-global-utility">
                            <button type="button" onClick={triggerGlobalMenu} data-toggle="collapse" data-target="#ubc7-global-menu"><span>UBC Search</span></button>
                            <noscript><a id="ubc7-global-utility-no-script" href="https://www.ubc.ca/" title="UBC Search">UBC Search</a></noscript>
                        </div>
                    </div>
                </div>
            </header>
            <div id="ubc7-unit" className="row-fluid expand">
                <div className="container">
                    <div className="span12">
                        {/* <div className="navbar">
                            <a className="btn btn-navbar" 
                                style={{
                                }}
                                href="#" 
                                data-toggle="collapse" 
                                data-target="#ubc7-unit-navigation">
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </a>
                        </div> */}
                        <div id="ubc7-unit-name" className="ubc7-single-element">
                            <Link
                                to="/"
                                ><span id="ubc7-unit-identifier">UBC Graduation: Spring 2021</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div id="ubc7-unit-menu" className="navbar expand" role="navigation">
                <div className="navbar-inner expand">
                    <div className="container">
                        <div className="nav-collapse collapse" id="ubc7-unit-navigation">
                            <ul className="nav">
                                <li className="active"><a href="/" title="Home">Home</a></li>
                                <li><a href="#" title="First Link">First Link</a></li>
                                <li><a href="#" title="Second Link">Second Link</a></li>
                                <li><a href="#" title="Third Link">Third Link</a></li>
                                <li className="dropdown">
                                    <div className="btn-group">
                                        <a className="btn" href="#">Split dropdown</a>
                                        <button className="btn dropdown-toggle" data-toggle="dropdown"><span className="ubc7-arrow blue down-arrow"></span></button>
                                        <ul className="dropdown-menu">
                                            <li><a href="#" title="Action">Action</a></li>
                                            <li><a href="#" title="Another Action">Another action</a></li>
                                            <li><a href="#" title="Something else">Something else</a></li>
                                            <li className="nav-header">Navigation header</li>
                                            <li><a href="#" title="Separated link">Separated link</a></li>
                                            <li><a href="#" title="One more">One more separated link</a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="dropdown">
                                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">Basic dropdown <div className="ubc7-arrow down-arrow"></div></a>
                                    <ul className="dropdown-menu">
                                        <li><a href="#" title="Action">Action</a></li>
                                        <li><a href="#" title="Another Action">Another action</a></li>
                                        <li><a href="#" title="Something else">Something else</a></li>
                                        <li className="nav-header">Navigation header</li>
                                        <li><a href="#" title="Separated link">Separated link</a></li>
                                        <li><a href="#" title="One more">One more separated link</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}
        </React.Fragment>
    )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
