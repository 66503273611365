// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

const Footer = () => (
  <React.Fragment>
    <footer id="ubc7-footer" className="expand" role="contentinfo">
        {/* <div className="row-fluid expand" id="ubc7-unit-footer">
            <div className="container">
                <div className="span10" id="ubc7-unit-address">
                    <div id="ubc7-address-unit-name">Unit Name</div>
                    <div id="ubc7-address-campus">Vancouver Campus | Okanagan Campus</div>
                    <div id="ubc7-address-street">1234 Street Name</div>
                    <div id="ubc7-address-location">
                        <span id="ubc7-address-city">Vancouver | Kelowna</span>, <span id="ubc7-address-province" title="British Columbia">BC</span> <span id="ubc7-address-country">Canada</span> <span id="ubc7-address-postal">V0V 0V0</span>
                    </div>
                    <div id="ubc7-address-phone">Tel XXX XXX XXXX</div>
                    <div id="ubc7-address-email">E-mail email@ubc.ca</div>
                </div>
                <div className="span2">
                    <strong>Find us on</strong>
                    <div id="ubc7-unit-social-icons">
                    </div>
                </div>
            </div>
        </div> */}
        <div className="row-fluid expand" id="ubc7-global-footer">
            <div className="container">
                <div className="span5" id="ubc7-signature"><a href="https://www.ubc.ca/" title="The University of British Columbia (UBC)">The University of British Columbia</a></div>
                <div className="span7" id="ubc7-footer-menu">
                    <div className="row-fluid"><div className="span6"><h3>About UBC</h3><div><a href="https://cdn.ubc.ca/clf/ref/contact">Contact UBC</a></div><div><a href="https://cdn.ubc.ca/clf/ref/about">About the University</a></div><div><a href="https://cdn.ubc.ca/clf/ref/news">News</a></div><div><a href="https://cdn.ubc.ca/clf/ref/events">Events</a></div><div><a href="https://cdn.ubc.ca/clf/ref/careers">Careers</a></div><div><a href="https://cdn.ubc.ca/clf/ref/gift">Make a Gift</a></div><div><a href="https://cdn.ubc.ca/clf/ref/search">Search UBC.ca</a></div></div><div className="span6"><h3>UBC Campuses</h3><div><a href="https://cdn.ubc.ca/clf/ref/vancouver">Vancouver Campus</a></div><div><a href="https://cdn.ubc.ca/clf/ref/okanagan">Okanagan Campus</a></div><h4>UBC Sites</h4><div><a href="https://cdn.ubc.ca/clf/ref/robson">Robson Square</a></div><div><a href="https://cdn.ubc.ca/clf/ref/centre-for-digital-media">Centre for Digital Media</a></div><div><a href="https://cdn.ubc.ca/clf/ref/medicine">Faculty of Medicine Across BC</a></div></div></div>
                </div>
            </div>
        </div>
        <div className="row-fluid expand" id="ubc7-minimal-footer">
            <div className="container">
                <div className="span12">
                    <ul>
                        <li><a href="//cdn.ubc.ca/clf/ref/emergency" title="Emergency Procedures">Emergency Procedures</a> <span className="divider">|</span></li>
                        <li><a href="//cdn.ubc.ca/clf/ref/terms" title="Terms of Use">Terms of Use</a> <span className="divider">|</span></li>
                        <li><a href="//cdn.ubc.ca/clf/ref/copyright" title="UBC Copyright">Copyright</a> <span className="divider">|</span></li>
                        <li><a href="//cdn.ubc.ca/clf/ref/accessibility" title="Accessibility">Accessibility</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
  </React.Fragment>
)


export default Footer
